@charset "UTF-8";
/* variables */
.transitions {
  transition: all 0.3s ease;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  width: 100%;
  max-width: 664px;
  padding: 0 1rem;
  margin: 0 auto;
  min-width: 320px;
  box-sizing: border-box;
}

/*mixins*/
body {
  -webkit-text-size-adjust: 100%;
}

input[type=submit],
input[type=text],
input[type=password],
input[type=file],
textarea {
  -webkit-appearance: none;
}

textarea,
input[type=submit],
input[type=text] {
  -webkit-border-radius: 0;
}

input[type=image] {
  padding: 0;
  border: none;
}

img {
  vertical-align: top;
  border: none;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input, textarea, select {
  vertical-align: middle;
  color: #000;
}

label {
  vertical-align: middle;
}

input:focus {
  outline: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

figure {
  margin: 1em 40px;
}

audio {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}

canvas {
  display: inline-block;
  vertical-align: baseline;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

video {
  display: inline-block;
  vertical-align: baseline;
}

[hidden] {
  display: none;
}

template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

img {
  border: 0;
  border: none;
  max-width: 100%;
}

hr {
  box-sizing: content-box;
  height: 0;
}

small {
  font-size: 80%;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

pre {
  overflow: auto;
  font-size: 1em;
}

code {
  font-size: 1em;
}

kbd {
  font-size: 1em;
}

samp {
  font-size: 1em;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_Bold.woff2") format("woff2"), url("../fonts/Gilroy_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_SemiBold.woff2") format("woff2"), url("../fonts/Gilroy_SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_Medium.woff2") format("woff2"), url("../fonts/Gilroy_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_Regular.woff2") format("woff2"), url("../fonts/Gilroy_Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #2d2d2d;
}

html {
  font-size: 62.5%;
}
html input[type=button] {
  cursor: pointer;
}
html input[disabled] {
  cursor: default;
}

body {
  overflow-x: hidden;
  position: relative;
  font-family: "Gilroy", Arial, Helvetica, sans-serif;
  background-color: #e5e5e5;
}

.lock {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}

a {
  color: #2d2d2d;
  text-decoration: none;
}
a:active {
  outline: 0;
}
a:hover {
  outline: 0;
  text-decoration: none;
}

ul {
  list-style: none;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
.submit-spinner {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: -0.125em;
  border: 0.2em solid #808080;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  margin-left: 1rem;
}

.empty-error,
.incomplete-error {
  display: none;
  position: absolute;
  top: 1.9rem;
  right: 2.1rem;
  z-index: 5;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #ee0029;
}
@media (max-width: 34.375em) {
  .empty-error,
.incomplete-error {
    top: 1.3rem;
    right: 1.3rem;
  }
}
@media (max-width: 30em) {
  .empty-error,
.incomplete-error {
    position: relative;
    top: 0;
    right: 0;
    padding-left: 1.4rem;
    margin-top: 0.3rem;
  }
}

.empty-error-visible,
.incomplete-error-visible {
  display: block;
}

.main-wrapper {
  max-width: 128rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  background-color: #f0f2f6;
}
@media (max-width: 36.25em) {
  .main-wrapper {
    padding: 0 1rem;
  }
}

.content-wrapper {
  max-width: 118.4rem;
  width: 100%;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3.4rem 0;
}
.header__logo {
  cursor: pointer;
  width: 19.2rem;
  height: 5.2rem;
}
@media (max-width: 25em) {
  .header__logo {
    width: 17rem;
    height: 4.5rem;
  }
}
@media (max-width: 31.25em) {
  .header {
    padding: 2rem 0;
  }
}

.cabinet {
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 25em) {
  .cabinet {
    margin-left: 1rem;
  }
}
.cabinet__logo {
  cursor: pointer;
  width: 2rem;
  height: 1.9rem;
  stroke: #ee0029;
  stroke-width: 2;
  transition: all 0.1s ease-in;
}
.cabinet__logo:hover {
  stroke: #f75d77;
}
.cabinet__text {
  font-size: 1.4rem;
  line-height: 2rem;
  text-decoration: underline;
  margin-left: 0.8rem;
  cursor: pointer;
}
@media (max-width: 25em) {
  .cabinet__text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-left: 5px;
  }
}

.logos__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logos__image {
  display: block;
  width: 100%;
  max-width: 35.4rem;
}
@media (max-width: 31.25em) {
  .logos__image {
    max-width: 26.4rem;
  }
}

.reinvest {
  margin: 4.3rem 0;
}
@media (max-width: 31.25em) {
  .reinvest {
    margin: 1.5rem 0;
  }
}
.reinvest__logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reinvest__logo {
  cursor: pointer;
  width: 2.2rem;
  height: 2.2rem;
  display: block;
  transition: all 0.2s ease-in;
}
.reinvest__logo:hover {
  transform: rotate(180deg);
}
@media (max-width: 31.25em) {
  .reinvest__logo {
    width: 2rem;
    height: 2rem;
  }
}
.reinvest__text {
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: 500;
  letter-spacing: 6.5px;
  color: #848484;
  margin-left: 1.5rem;
}
@media (max-width: 31.25em) {
  .reinvest__text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.heading {
  width: 100%;
  max-width: 58rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.heading__primary {
  font-size: 3.2rem;
  line-height: 3.9rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 2.1rem;
}
@media (max-width: 31.25em) {
  .heading__primary {
    font-size: 2.6rem;
    line-height: 3.2rem;
    margin-bottom: 1.2rem;
  }
}
.heading__text {
  font-size: 1.8rem;
  line-height: 2.5rem;
  margin-bottom: 2.1rem;
}
@media (max-width: 31.25em) {
  .heading__text {
    text-align: left;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12rem;
}
@media (max-width: 53.125em) {
  .form {
    padding-bottom: 4rem;
  }
}
@media (max-width: 26.875em) {
  .form {
    padding-bottom: 3rem;
  }
}
.form__wrapper {
  width: 100%;
  max-width: 55rem;
  margin: 0 auto;
}
.form__info-text {
  font-size: 1.4rem;
  line-height: 1.6rem;
  opacity: 50%;
  padding-left: 2.1rem;
  margin-bottom: 1rem;
}
@media (max-width: 34.375em) {
  .form__info-text {
    padding-left: 1.4rem;
  }
}
@media (max-width: 31.25em) {
  .form__info-text {
    padding-left: 1.2rem;
    margin-bottom: 0.5rem;
  }
}
.form__input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.form__input-wrapper:not(:last-of-type) {
  margin-bottom: 1.4rem;
}
.form__input-wrapper input {
  font-size: 1.8rem;
  line-height: 2.5rem;
  padding: 1.2rem 2.1rem;
  padding-right: 30rem;
  border: 1px solid #c4c4c4;
  border-radius: 1rem;
  font-family: inherit;
  background-color: #f0f2f6;
  color: #2d2d2d;
}
.form__input-wrapper input::placeholder {
  color: #2d2d2d;
  transition: all 0.2s ease-in;
}
.form__input-wrapper input:focus::placeholder {
  color: rgba(45, 45, 45, 0.5);
}
.form__input-wrapper input.error-border {
  border: 1px solid #ee0029;
}
@media (max-width: 34.375em) {
  .form__input-wrapper input {
    padding: 0.8rem 1.2rem;
    padding-right: 29rem;
  }
}
@media (max-width: 30em) {
  .form__input-wrapper input {
    padding-right: 1.2rem;
  }
}
.form__input-wrapper label {
  font-size: 1.4rem;
  line-height: 1.6rem;
  opacity: 50%;
  padding-left: 2.3rem;
  margin-top: 0.3rem;
}
@media (max-width: 34.375em) {
  .form__input-wrapper label {
    padding-left: 1.4rem;
  }
}
@media (max-width: 31.25em) {
  .form__input-wrapper label {
    padding-left: 1.4rem;
  }
}
.form__disclaimer {
  margin: 2.9rem 33.1rem 3.9rem 36.5rem;
  width: 100%;
  max-width: 58.4rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.form__disclaimer a {
  text-decoration: underline;
}
@media (max-width: 43.75em) {
  .form__disclaimer {
    margin: 2.9rem auto 3.9rem;
  }
}
@media (max-width: 26.875em) {
  .form__disclaimer {
    margin: 2rem auto 3rem;
  }
}
.form__btn {
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  background-color: #ee0029;
  padding: 1.2rem 1rem;
  width: 22rem;
  border: none;
  border-radius: 2.4rem;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-family: inherit;
}
.form__btn:disabled {
  cursor: default;
  background-color: #f7f8fb;
  color: #808080;
  font-weight: 500;
  font-family: inherit;
}

.prefooter {
  text-align: center;
  padding-bottom: 2rem;
}
.prefooter__text {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 600;
  color: #ee0029;
}
@media (max-width: 22.5em) {
  .prefooter__text {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.prefooter__text--nowrap {
  white-space: nowrap;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 600;
  color: #ee0029;
}
@media (max-width: 22.5em) {
  .prefooter__text--nowrap {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.footer {
  background-color: #e6e6e6;
  padding: 5rem 2rem;
}
@media (max-width: 31.25em) {
  .footer {
    padding: 3rem 1rem;
  }
}
.footer__wrapper {
  max-width: 118.4rem;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(20rem, max-content) minmax(30rem, 1fr) max-content;
  align-items: start;
}
@media (max-width: 53.75em) {
  .footer__wrapper {
    grid-template-columns: minmax(20rem, 26rem) minmax(24rem, 1fr) max-content;
  }
}
@media (max-width: 50em) {
  .footer__wrapper {
    grid-template-columns: minmax(20rem, 1fr) minmax(20rem, 1fr);
    grid-template-rows: repeat(2, max-content);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    align-items: center;
  }
}
@media (max-width: 31.25em) {
  .footer__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, max-content);
    grid-row-gap: 2rem;
  }
}
.footer__text-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-right: 11rem;
}
@media (max-width: 71.25em) {
  .footer__text-wrapper {
    margin-top: 0.5rem;
    margin-right: 0;
  }
}
@media (max-width: 50em) {
  .footer__text-wrapper {
    grid-column: 1/3;
    grid-row: 2/3;
    justify-self: center;
  }
}
@media (max-width: 31.25em) {
  .footer__text-wrapper {
    grid-column: 1/2;
    grid-row: 4/5;
    align-items: center;
    margin-top: 0;
  }
}
.footer__title {
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #5b6770;
  margin-bottom: 1.5rem;
}
.footer__title--upper {
  display: none;
}
@media (max-width: 31.25em) {
  .footer__title--upper {
    display: block;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #5b6770;
    text-align: center;
    margin-bottom: 0.5rem;
    grid-row: 1/2;
    grid-column: 1/2;
  }
}
@media (max-width: 62.5em) {
  .footer__title {
    margin-bottom: 0;
  }
}
@media (max-width: 31.25em) {
  .footer__title {
    display: none;
  }
}
.footer__number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 50em) {
  .footer__number-wrapper {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media (max-width: 31.25em) {
  .footer__number-wrapper {
    grid-column: 1/2;
    grid-row: 2/3;
    justify-self: center;
    align-items: center;
  }
}
.footer__text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #5b6770;
}
@media (max-width: 31.25em) {
  .footer__text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: center;
  }
}
.footer__phone {
  font-size: 3.6rem;
  line-height: 4.8rem;
  font-weight: 600;
  color: #5b6770;
  margin-bottom: 1.5rem;
}
@media (max-width: 62.5em) {
  .footer__phone {
    margin-bottom: 0;
  }
}
@media (max-width: 38.75em) {
  .footer__phone {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.footer__time {
  font-size: 1.4rem;
  line-height: 2.4rem;
}
.footer__icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.footer__icon-wrapper a:not(:last-of-type) {
  margin-right: 3.7rem;
}
@media (max-width: 53.75em) {
  .footer__icon-wrapper a:not(:last-of-type) {
    margin-right: 1.5rem;
  }
}
@media (max-width: 50em) {
  .footer__icon-wrapper a:not(:last-of-type) {
    margin-right: 3.5rem;
  }
}
@media (max-width: 40em) {
  .footer__icon-wrapper a:not(:last-of-type) {
    margin-right: 2.5rem;
  }
}
@media (max-width: 34.375em) {
  .footer__icon-wrapper a:not(:last-of-type) {
    margin-right: 1.5rem;
  }
}
@media (max-width: 31.25em) {
  .footer__icon-wrapper a:not(:last-of-type) {
    margin-right: 2rem;
  }
}
@media (max-width: 71.25em) {
  .footer__icon-wrapper {
    justify-content: center;
    padding: 0 2rem;
  }
}
@media (max-width: 50em) {
  .footer__icon-wrapper {
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 0;
  }
}
@media (max-width: 31.25em) {
  .footer__icon-wrapper {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
.footer__icon {
  width: 3.5rem;
  height: 3.5rem;
  display: block;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  padding: 0 1rem;
  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.popup__wrapper {
  max-width: 38rem;
  width: 100%;
  background-color: #ffffff;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
}
.popup__content h2 {
  text-transform: none;
  color: #333333;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 700;
  text-align: center;
  font-family: inherit;
  color: #ee0029;
  margin-bottom: 1rem;
}
.popup__content h2.green {
  color: #0b8b0b;
}
.popup__content p {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 2rem;
}
.popup__close-btn {
  align-self: flex-end;
  margin-bottom: 0.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.popup__close-icon {
  width: 2rem;
  height: 2rem;
  display: block;
  outline: none;
  stroke-width: 1px;
}

.invisible {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.modal__overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  background-color: #ffffff;
  padding: 2rem 2rem 4rem;
  max-width: 40rem;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  align-self: flex-end;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.modal__close::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1000;
}

.modal__header .modal__close:before {
  content: "✕";
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.micromodal-slide.is-open {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}