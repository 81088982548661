.transitions {
  transition: all .3s ease;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper {
  width: 100%;
  max-width: 664px;
  padding:0 1rem;
  margin: 0 auto;
  min-width: 320px;
  box-sizing: border-box;
}