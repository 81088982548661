@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_Bold.woff2") format("woff2"),
    url("../fonts/Gilroy_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_SemiBold.woff2") format("woff2"),
    url("../fonts/Gilroy_SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_Medium.woff2") format("woff2"),
    url("../fonts/Gilroy_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy_Regular.woff2") format("woff2"),
    url("../fonts/Gilroy_Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
