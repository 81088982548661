body {-webkit-text-size-adjust:100%;}
input[type=submit],
input[type=text],
input[type=password],
input[type=file],
textarea{-webkit-appearance:none;}
textarea,
input[type=submit],
input[type=text]{-webkit-border-radius:0;}
input[type="image"]{
  padding:0;
  border:none;
}
img {
  vertical-align: top;
  border: none;
}
form,
fieldset {
  margin:0;
  padding:0;
  border-style:none;
}
input, textarea, select {
  vertical-align:middle;
  color:#000;
}
label {vertical-align:middle;}
input:focus {
  outline:none;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}
figure {
  margin: 1em 40px;
}
audio {
  display: inline-block;
  vertical-align: baseline;
  &:not([controls]) {
    display: none;
    height: 0;
  }
}
canvas {
  display: inline-block;
  vertical-align: baseline;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
video {
  display: inline-block;
  vertical-align: baseline;
}
[hidden] {
  display: none;
}
template {
  display: none;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
img {
  border: 0;
  border: none;
  max-width: 100%;
}
hr {
  box-sizing: content-box;
  height: 0;
}
small {
  font-size: 80%;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
pre {
  overflow: auto;
  font-size: 1em;
}
code {
  font-size: 1em;
}
kbd {
  font-size: 1em;
}
samp {
  font-size: 1em;
}
