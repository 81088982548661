@import "fonts";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $color_text;
}

html {
  font-size: 62.5%;

  input[type="button"] {
    cursor: pointer;
  }

  input[disabled] {
    cursor: default;
  }
}

body {
  overflow-x: hidden;
  position: relative;
  font-family: $font_regular;
  background-color: $color_bg;
}

.lock {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}
a {
  color: $color_text;
  text-decoration: none;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
    text-decoration: none;
  }
}

ul {
  list-style: none;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}

.submit-spinner {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: -0.125em;
  border: 0.2em solid #808080;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  margin-left: 1rem;
}

.empty-error,
.incomplete-error {
  display: none;
  position: absolute;
  top: 1.9rem;
  right: 2.1rem;
  z-index: 5;

  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #ee0029;

  @media (max-width: 34.375em) {
    top: 1.3rem;
    right: 1.3rem;
  }

  @media (max-width: 30em) {
    position: relative;
    top: 0;
    right: 0;
    padding-left: 1.4rem;
    margin-top: 0.3rem;
  }
}

.empty-error-visible,
.incomplete-error-visible {
  display: block;
}
