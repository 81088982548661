.main-wrapper {
  max-width: 128rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  background-color: #f0f2f6;

  @media (max-width: 36.25em) {
    padding: 0 1rem;
  }
}

.content-wrapper {
  max-width: 118.4rem;
  width: 100%;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3.4rem 0;

  &__logo {
    cursor: pointer;
    width: 19.2rem;
    height: 5.2rem;

    @media (max-width: 25em) {
      width: 17rem;
      height: 4.5rem;
    }
  }

  @media (max-width: 31.25em) {
    padding: 2rem 0;
  }
}

.cabinet {
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 25em) {
    margin-left: 1rem;
  }

  &__logo {
    cursor: pointer;
    width: 2rem;
    height: 1.9rem;
    stroke: #ee0029;
    stroke-width: 2;
    transition: all 0.1s ease-in;

    &:hover {
      stroke: #f75d77;
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2rem;
    text-decoration: underline;

    margin-left: 0.8rem;
    cursor: pointer;

    @media (max-width: 25em) {
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-left: 5px;
    }
  }
}

.logos {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    display: block;
    width: 100%;
    max-width: 35.4rem;

    @media (max-width: 31.25em) {
      max-width: 26.4rem;
    }
  }
}

.reinvest {
  margin: 4.3rem 0;

  @media (max-width: 31.25em) {
    margin: 1.5rem 0;
  }

  &__logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__logo {
    cursor: pointer;
    width: 2.2rem;
    height: 2.2rem;
    display: block;
    transition: all 0.2s ease-in;

    &:hover {
      transform: rotate(180deg);
    }

    @media (max-width: 31.25em) {
      width: 2rem;
      height: 2rem;
    }
  }

  &__text {
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 500;
    letter-spacing: 6.5px;
    color: #848484;

    margin-left: 1.5rem;

    @media (max-width: 31.25em) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

.heading {
  width: 100%;
  max-width: 58rem;
  text-align: center;
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  &__primary {
    font-size: 3.2rem;
    line-height: 3.9rem;
    font-weight: 700;
    color: #000;

    margin-bottom: 2.1rem;

    @media (max-width: 31.25em) {
      font-size: 2.6rem;
      line-height: 3.2rem;
      margin-bottom: 1.2rem;
    }
  }

  &__text {
    font-size: 1.8rem;
    line-height: 2.5rem;

    margin-bottom: 2.1rem;

    @media (max-width: 31.25em) {
      text-align: left;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12rem;

  @media (max-width: 53.125em) {
    padding-bottom: 4rem;
  }

  @media (max-width: 26.875em) {
    padding-bottom: 3rem;
  }

  &__wrapper {
    width: 100%;
    max-width: 55rem;
    margin: 0 auto;
  }

  &__info-text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    opacity: 50%;

    padding-left: 2.1rem;
    margin-bottom: 1rem;

    @media (max-width: 34.375em) {
      padding-left: 1.4rem;
    }

    @media (max-width: 31.25em) {
      padding-left: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    &:not(:last-of-type) {
      margin-bottom: 1.4rem;
    }

    & input {
      font-size: 1.8rem;
      line-height: 2.5rem;
      padding: 1.2rem 2.1rem;
      padding-right: 30rem;
      border: 1px solid #c4c4c4;
      border-radius: 1rem;
      font-family: inherit;
      background-color: #f0f2f6;
      color: #2d2d2d;

      &::placeholder {
        color: #2d2d2d;
        transition: all 0.2s ease-in;
      }

      &:focus::placeholder {
        color: rgba($color_text, 0.5);
      }

      &.error-border {
        border: 1px solid #ee0029;
      }

      @media (max-width: 34.375em) {
        padding: 0.8rem 1.2rem;
        padding-right: 29rem;
      }

      @media (max-width: 30em) {
        padding-right: 1.2rem;
      }
    }

    & label {
      font-size: 1.4rem;
      line-height: 1.6rem;
      opacity: 50%;

      padding-left: 2.3rem;
      margin-top: 0.3rem;

      @media (max-width: 34.375em) {
        padding-left: 1.4rem;
      }

      @media (max-width: 31.25em) {
        padding-left: 1.4rem;
      }
    }
  }

  &__disclaimer {
    margin: 2.9rem 33.1rem 3.9rem 36.5rem;
    width: 100%;
    max-width: 58.4rem;

    font-size: 1.2rem;
    line-height: 1.6rem;

    & a {
      text-decoration: underline;
    }

    @media (max-width: 43.75em) {
      margin: 2.9rem auto 3.9rem;
    }

    @media (max-width: 26.875em) {
      margin: 2rem auto 3rem;
    }
  }

  &__btn {
    color: #fff;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
    background-color: #ee0029;
    padding: 1.2rem 1rem;
    width: 22rem;
    border: none;
    border-radius: 2.4rem;
    cursor: pointer;
    transition: all 0.3s ease-in;
    font-family: inherit;

    &:disabled {
      cursor: default;
      background-color: #f7f8fb;
      color: #808080;
      font-weight: 500;
      font-family: inherit;
    }
  }
}

.prefooter {
  text-align: center;
  padding-bottom: 2rem;

  &__text {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 600;
    color: #ee0029;

    @media (max-width: 22.5em) {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &--nowrap {
      white-space: nowrap;
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 600;
      color: #ee0029;

      @media (max-width: 22.5em) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}

.footer {
  background-color: #e6e6e6;
  padding: 5rem 2rem;

  @media (max-width: 31.25em) {
    padding: 3rem 1rem;
  }

  &__wrapper {
    max-width: 118.4rem;
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: minmax(20rem, max-content) minmax(30rem, 1fr) max-content;
    align-items: start;

    @media (max-width: 53.75em) {
      grid-template-columns: minmax(20rem, 26rem) minmax(24rem, 1fr) max-content;
    }

    @media (max-width: 50em) {
      grid-template-columns: minmax(20rem, 1fr) minmax(20rem, 1fr);
      grid-template-rows: repeat(2, max-content);
      grid-column-gap: 2rem;
      grid-row-gap: 3rem;
      align-items: center;
    }

    @media (max-width: 31.25em) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, max-content);
      grid-row-gap: 2rem;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;

    margin-top: 2rem;
    margin-right: 11rem;

    @media (max-width: 71.25em) {
      margin-top: 0.5rem;
      margin-right: 0;
    }

    @media (max-width: 50em) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      justify-self: center;
    }

    @media (max-width: 31.25em) {
      grid-column: 1 / 2;
      grid-row: 4 / 5;
      align-items: center;
      margin-top: 0;
    }
  }

  &__title {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #5b6770;

    margin-bottom: 1.5rem;

    &--upper {
      display: none;

      @media (max-width: 31.25em) {
        display: block;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: #5b6770;
        text-align: center;
        margin-bottom: 0.5rem;

        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }
    }

    @media (max-width: 62.5em) {
      margin-bottom: 0;
    }

    @media (max-width: 31.25em) {
      display: none;
    }
  }

  &__number-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 50em) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    @media (max-width: 31.25em) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      justify-self: center;
      align-items: center;
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #5b6770;

    @media (max-width: 31.25em) {
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
    }
  }

  &__phone {
    font-size: 3.6rem;
    line-height: 4.8rem;
    font-weight: 600;
    color: #5b6770;

    margin-bottom: 1.5rem;

    @media (max-width: 62.5em) {
      margin-bottom: 0;
    }

    @media (max-width: 38.75em) {
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  &__time {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  &__icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & a:not(:last-of-type) {
      margin-right: 3.7rem;

      @media (max-width: 53.75em) {
        margin-right: 1.5rem;
      }

      @media (max-width: 50em) {
        margin-right: 3.5rem;
      }

      @media (max-width: 40em) {
        margin-right: 2.5rem;
      }

      @media (max-width: 34.375em) {
        margin-right: 1.5rem;
      }

      @media (max-width: 31.25em) {
        margin-right: 2rem;
      }
    }

    @media (max-width: 71.25em) {
      justify-content: center;
      padding: 0 2rem;
    }

    @media (max-width: 50em) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding: 0;
    }

    @media (max-width: 31.25em) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
  }

  &__icon {
    width: 3.5rem;
    height: 3.5rem;
    display: block;
  }
}
