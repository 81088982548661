.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  padding: 0 1rem;

  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.8);

  &__wrapper {
    max-width: 38rem;
    width: 100%;
    background-color: #ffffff;
    padding: 3rem 2rem;

    display: flex;
    flex-direction: column;
  }

  &__content {
    h2 {
      text-transform: none;
      color: #333333;
      font-size: 2.4rem;
      line-height: 2.4rem;
      font-weight: 700;
      text-align: center;
      font-family: inherit;
      color: #ee0029;
      margin-bottom: 1rem;

      &.green {
        color: rgb(11, 139, 11);
      }
    }
    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &__close-btn {
    align-self: flex-end;
    margin-bottom: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__close-icon {
    width: 2rem;
    height: 2rem;
    display: block;
    outline: none;
    stroke-width: 1px;
  }
}

.invisible {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}
